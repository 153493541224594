.container {
  background: linear-gradient(var(--color-grey-80), var(--color-black));
  color: var(--color-grey-20);

  .footerNav {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;

    a,
    button {
      background: transparent;
      color: var(--color-white);
      font-size: 1rem;
      font-weight: bold;
      padding: 0 0.75rem;
      text-decoration: underline;
    }
  }
}

// Mobile styling
@media screen and (max-width: 768px) {
  .footerNav {
    align-items: center;
    flex-direction: column;

    a,
    button {
      margin-bottom: 1.25rem;
    }
  }
}
