.patientPhoto {
  margin: 0 auto;
  max-width: 50%;
}

.caption {
  font-style: italic;
  text-align: center;
}

.btn {
  flex: 1;
  margin: 0.5rem;
}

.icon {
  cursor: pointer;
  /* stylelint-disable selector-no-id, declaration-no-important */
  height: 3rem !important;
  pointer-events: all !important;
  right: 0.25rem !important;
}

.search {
  background: transparent;
  border: 0 none;
  cursor: pointer;
  height: auto;
  margin: 0;
  outline: 0;
  padding: 0;
}

@media only screen and (min-width: 512px) {
  .columns {
    margin-left: auto;
    margin-right: auto;
    max-width: 70%;
  }
}
